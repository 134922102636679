/* eslint-disable vue/require-v-for-key */
<template>
  <div class="r-template">
    <div v-if="loading" class="r-template-1-loading">
      <svg
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path
          fill="#999"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    <div v-else :class="`r-template-1 lang-${language}`">
      <div v-if="!isPremium && (canPreview || getUser.level >= PERM_ADMIN)" class="r-template-1-premium-preview" :class="{'r-template-1-premium-preview-on': showPremium}">
        <el-button
          :type="showPremium ? 'default' : 'primary'"
          icon="el-icon-view"
          round
          @click="$store.commit('setShowPremium', !showPremium)"
        >{{ showPremium ? $t('general.previewFree') : $t('general.previewPremium') }}</el-button>
      </div>
      <div class="r-template-1-order" v-if="showPremium && this.getRestaurant.r_id > 0">
        <el-badge
          slot="reference"
          :value="order.cart.items.length"
          :max="99"
          class="r-template-1-order-item-count"
        >
          <el-button
            type="primary"
            icon="el-icon-s-order"
            circle
            @click="showDialogOrder"
          ></el-button>
        </el-badge>
      </div>

      <div id="r-section-home" class="r-template-1-header">
        <img
          v-if="getRestaurant.r_image"
          :src="getImageURI(getRestaurant.r_image, 'fhd')"
          class="r-template-1-header-image"
        />
        <div class="r-template-1-header-brand">
          <div
            v-if="
              getRestaurant.r_logo &&
              (getRestaurant.r_display_logo == 0 ||
                getRestaurant.r_display_logo == 4)
            "
            class="r-template-1-header-brand-logo"
          >
            <img
              :src="getImageURI(getRestaurant.r_logo, 's500')"
              class="r-template-1-header-brand-logo-image"
              :style="getLogoStyles"
            />
          </div>
          <div
            v-else-if="
              getRestaurant.r_display_logo == 1 ||
              getRestaurant.r_display_logo == 4
            "
            class="r-template-1-header-brand-title"
            :key="language"
          >
            {{ getText(getRestaurant.r_name_lh) }}
          </div>
        </div>
        <div class="r-template-1-share">
          <span
            class="r-template-1-share-link"
            @click="showDialogShareLink(false, false)"
          >
            <i class="el-icon-share" />
            {{ $t('general.share') }}
          </span>
        </div>
      </div>

      <div id="r-section-about" class="r-template-1-segment-text">
        <h1>{{ $t('general.aboutUs') }}</h1>
        <div
          class="r-template-1-about"
          :class="{ 'r-template-1-about-full': about.full }"
          :key="language"
        >
          <div
            ref="about"
            class="r-template-about-text"
            v-html="getText(getRestaurant.r_text_lh)"
          />
          <div
            v-if="about.collapsable"
            class="r-template-1-about-more"
            @click="about.full = !about.full"
          >
            <span v-if="!about.full" class="r-template-1-about-more-text"
              ><i class="el-icon-plus" />{{ $t('restaurant.readMore') }}</span
            >
            <span v-else class="r-template-1-about-more-text"
              ><i class="el-icon-minus" />{{ $t('restaurant.hide') }}</span
            >
          </div>
        </div>
      </div>

      <div
        v-if="showPremium && getActiveMenues.length"
        id="r-section-menues"
        class="r-template-1-segment-menues-main"
      >
        <h1>{{ $t('general.menus') }}</h1>
        <div class="r-template-1-segment-menues-items">
          <div
            v-for="menu in getActiveMenues"
            :key="menu.rmm_id"
            class="r-template-1-segment-menues-item"
            @click="showDialogMenues(menu)"
          >
            <div class="r-template-1-segment-menues-item-content">
              <div class="r-template-1-segment-menues-item-image">
                <img
                  v-if="menu.rmm_image"
                  :src="getImageURI(menu.rmm_image, 'hd')"
                  loading="lazy"
                />
                <div v-else class="r-template-1-img-fallback" />
              </div>
              <div
                class="r-template-1-segment-menues-item-title"
                :key="`menu-title-${language}-${menu.rmm_id}`"
              >
                {{ getText(menu.rmm_name_lh) }}
              </div>
              <div
                v-if="showPremium && (menu.rmm_from || menu.rmm_to)"
                class="r-template-1-segment-menues-item-timeframe"
              >
                <template v-if="menu.rmm_from">
                  <div class="r-template-1-segment-menues-item-timeframe-group">
                    <span
                      v-if="!menu.rmm_to"
                      class="r-template-1-segment-menues-item-timeframe-title"
                      >{{ $t('general.from') }}</span
                    >
                    <span
                      class="r-template-1-segment-menues-item-timeframe-value"
                      >{{ toLocal(menu.rmm_from) }}</span
                    >
                  </div>
                </template>
                <template v-if="menu.rmm_from && menu.rmm_to">
                  <div class="r-template-1-segment-menues-item-timeframe-group">
                    <span><i class="el-icon-right" /></span>
                  </div>
                </template>
                <template v-if="menu.rmm_to">
                  <div class="r-template-1-segment-menues-item-timeframe-group">
                    <span
                      v-if="!menu.rmm_from"
                      class="r-template-1-segment-menues-item-timeframe-title"
                      >{{ $t('general.until') }}</span
                    >
                    <span
                      class="r-template-1-segment-menues-item-timeframe-value"
                      >{{ toLocal(menu.rmm_to) }}</span
                    >
                  </div>
                </template>
              </div>
              <div
                v-if="menu.rmm_weekdays > 0 && menu.rmm_weekdays < 127 && showPremium"
                class="r-template-1-segment-menues-item-weekdays"
              >
                <template v-for="weekday in weekdays">
                  <span
                    v-if="menu.rmm_weekdays & weekday.value"
                    :key="`menu-weekday-${weekday.value}-${menu.rmm_id}`"
                    >{{ weekday.label }}</span
                  >
                </template>
              </div>
              <div class="r-template-1-segment-menues-item-price">
                <span class="r-template-1-price-takeaway"
                  ><i
                    v-if="isTakeaway && menu.rmm_takeaway === 1"
                    class="el-icon-sell"
                /></span>
                <span class="r-template-1-price">{{
                  formatPrice(menu.rmm_price)
                }}</span>
                <span class="r-template-1-price-currency">{{
                  getRestaurant.r_currency_sign
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showPremium && getActiveCategories.length"
        id="r-section-menu"
        class="r-template-1-segment-menu-main"
      >
        <h1>{{ $t('general.aLaCarteOurs') }}</h1>
        <div
          v-for="category in getActiveCategories"
          class="r-template-1-segment-menu"
          :key="`menu-cat-${category.rmcu_id}`"
          :id="`r-section-menu-cat-${category.rmcu_id}`"
        >
          <div
            v-if="category.rmcu_image"
            class="r-template-1-segment-menu-cat-image"
          >
            <img
              :src="getImageURI(category.rmcu_image, 'fhd')"
              loading="lazy"
            />
          </div>
          <div class="r-template-1-segment-menu-cat">
            <h2 :key="`category-${language}-${category.rmcu_id}`">
              {{ getText(category.rmcu_name_lh) }}
            </h2>
            <div class="r-template-1-segment-menu-cat-items">
              <div
                v-for="item in getMenuItems.filter(
                  (el) =>
                    el.rmi_visible === 1 && el.rmi_rmcu === category.rmcu_id
                )"
                :key="`item-${item.rmi_id}`"
                class="r-template-1-segment-menu-cat-item"
                @click="showDialogInfoItem(item)"
              >
                <div class="r-template-1-segment-menu-cat-item-image">
                  <img
                    v-if="item.rmi_image"
                    :src="getImageURI(item.rmi_image, 'xs')"
                    loading="lazy"
                  />
                  <div v-else class="r-template-1-img-fallback" />
                </div>
                <div
                  class="r-template-1-segment-menu-cat-item-title"
                  :key="`item-title-${language}-${item.rmi_id}`"
                >
                  {{ getText(item.rmi_name_lh) }}
                </div>
                <div class="r-template-1-segment-menu-cat-item-price">
                  <span class="r-template-1-price-takeaway"
                    ><i
                      v-if="isTakeaway && item.rmi_takeaway === 1"
                      class="el-icon-sell"
                  /></span>
                  <span class="r-template-1-price">{{
                    formatPrice(item.rmi_price)
                  }}</span>
                  <span class="r-template-1-price-currency">{{
                    getRestaurant.r_currency_sign
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="getGalleryImages.length > 0 && showPremium"
        id="r-section-gallery"
        class="r-template-1-gallery"
      >
        <div
          v-for="rgi in getGalleryImages"
          :key="`gallery-image-${rgi.rgi_image}`"
          class="r-template-1-gallery-item"
        >
          <img :src="getImageURI(rgi.rgi_image, 'hd')" loading="lazy" />
        </div>
      </div>

      <div id="r-section-contact" class="r-template-1-footer">
        <div class="r-template-1-segment-footer">
          <h1>{{ $t('restaurant.howWhere') }}</h1>
          <div class="r-template-1-footer-block">
            <div class="r-template-1-footer-block-restaurant" :key="language">
              <div class="r-template-1-footer-block-contact">
                <div v-if="getRestaurant.r_logo">
                  <img
                    :src="getImageURI(getRestaurant.r_logo, 's500')"
                    class="r-template-1-footer-block-contact-logo"
                    :style="getLogoStyles"
                  />
                </div>
                <div class="r-template-1-footer-block-contact-name">
                  {{ getText(getRestaurant.r_name_lh) }}
                </div>
                <div
                  v-if="getCuisinesOrdered"
                  class="r-template-1-footer-block-contact-cuisines"
                >
                  <i class="el-icon-food"></i>
                  <template v-for="(cuisine, cIdx) in getCuisinesOrdered"
                    >{{ cIdx ? ', ' : ''
                    }}{{ getText(cuisine.rcu_name_lh) }}</template
                  >
                </div>
                <div class="r-template-1-footer-block-contact-infos">
                  <div class="r-template-1-footer-block-contact-address">
                    {{ getRestaurant.r_address }}
                  </div>
                  <div class="r-template-1-footer-block-contact-zip-city">
                    {{ getRestaurant.r_zip }} - {{ getRestaurant.r_city }}
                  </div>
                  <div
                    v-if="getRestaurant.r_phone.length"
                    class="r-template-1-footer-block-contact-phone"
                  >
                    <i class="el-icon-phone-outline"></i>
                    {{ getRestaurant.r_phone }}
                  </div>
                  <div
                    v-if="getRestaurant.r_email.length"
                    class="r-template-1-footer-block-contact-email"
                  >
                    <i class="el-icon-message"></i>
                    {{ getRestaurant.r_email }}
                  </div>
                  <div
                    v-if="getRestaurant.r_web.length"
                    class="r-template-1-footer-block-contact-web"
                  >
                    <el-link
                      :href="
                        getRestaurant.r_web.includes('http')
                          ? getRestaurant.r_web
                          : `https://${getRestaurant.r_web}`
                      "
                      target="_blank"
                      icon="el-icon-discover"
                      >{{
                        getRestaurant.r_web.includes('http')
                          ? getRestaurant.r_web
                          : `https://${getRestaurant.r_web}`
                      }}</el-link
                    >
                  </div>
                  <div
                    v-if="getRestaurant.r_whatsapp.length"
                    class="r-template-1-footer-block-contact-whatsapp"
                  >
                    <a
                      target="_blank"
                      :href="`https://wa.me/${getRestaurant.r_whatsapp}`"
                      >{{ $t('restaurant.sendWhatsapp') }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="r-template-1-footer-block-hours">
              <div
                v-for="weekday in weekdays"
                class="r-template-1-footer-block-hours-weekday"
                :key="weekday.value"
              >
                <div class="r-template-1-footer-block-hours-weekday-day">
                  {{ weekday.label }}
                </div>
                <div class="r-template-1-footer-block-hours-weekday-times">
                  <template
                    v-if="
                      getRegularHours.filter(
                        (item) => item.rft_weekdays & weekday.value
                      ).length
                    "
                  >
                    <div
                      v-for="tf in getRegularHours.filter(
                        (item) => item.rft_weekdays & weekday.value
                      )"
                      :key="tf.rft_id"
                      class="r-template-1-footer-block-hours-weekday-time"
                    >
                      {{ tf.rft_from }} - {{ tf.rft_to }}
                    </div>
                  </template>
                  <div
                    v-else
                    class="
                      r-template-1-footer-block-hours-weekday-time
                      r-template-1-footer-block-hours-weekday-time-closed
                    "
                  >
                    {{ $t('general.closed') }}
                  </div>
                </div>
              </div>
              <div
                class="r-template-1-footer-block-hours-weekday flex-dummy"
                v-for="dummy in 3"
                :key="`dummy-${dummy}`"
              ></div>
              <div
                class="r-template-1-footer-block-hours-closed"
                v-if="getClosedDates.length"
              >
                <div class="r-template-1-footer-block-hours-closed-title">
                  {{ $t('general.closedHolidays') }}
                </div>
                <div class="r-template-1-footer-block-hours-closed-items">
                  <div
                    v-for="(tf, tfIdx) in getClosedDates"
                    class="r-template-1-footer-block-hours-closed-item"
                    :key="`tf-closed-${tfIdx}`"
                  >
                    {{ tf.from }} <i class="el-icon-right" /> {{ tf.to }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="r-template-1-footer-block">
            <div class="r-template-1-footer-block-map">
              <contact-map
                v-if="location"
                :lat="location.lat"
                :lng="location.lng"
              ></contact-map>
            </div>
          </div>
        </div>
      </div>

      <!-- Dialog Share Links" -->
      <el-dialog
        class="r-template-1-dialog r-template-1-dialog-share"
        :visible.sync="link.dialog.visible"
        :append-to-body="true"
      >
        <div class="r-template-1-dialog-share-header">
          <div class="r-template-1-dialog-share-header-bkg">
            <div class="r-template-1-dialog-share-header-title">
              <span class="el-icon-share"></span>
              {{ $t('general.share') }}
            </div>
            <div class="r-template-1-dialog-share-header-close">
              <div
                class="r-template-1-dialog-share-header-close"
                @click="link.dialog.visible = false"
              >
                <i class="el-icon-close" />
              </div>
            </div>
          </div>
        </div>
        <div class="r-template-1-dialog-share-content">
          <div class="r-template-1-dialog-share-content-header-bkg"></div>
          <div class="r-template-1-dialog-share-content-links">
            <!-- {{ $t('general.shareLinks') }} -->
          </div>
          <div class="r-template-1-dialog-share-content-buttons">
            <el-link
              class="r-template-1-dialog-share-content-button"
              placement="top-start"
              target="_blank"
              icon="el-icon-link"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${getShareUrl()}`"
            >
              Facebook
            </el-link>
            <el-link
              class="r-template-1-dialog-share-content-button"
              placement="top-start"
              target="_blank"
              :href="`https://telegram.me/share/url?url=${getShareUrl()}&text=`"
              icon="el-icon-link"
              >Telegram
            </el-link>
            <el-link
              class="r-template-1-dialog-share-content-button"
              placement="top-start"
              target="_blank"
              :href="`https://twitter.com/intent/tweet?text=${getShareUrl()}`"
              icon="el-icon-link"
              >Twitter
            </el-link>
            <el-link
              class="r-template-1-dialog-share-content-button"
              placement="top-start"
              target="_blank"
              :href="`https://api.whatsapp.com/send?phone=&text=${getShareUrl()}`"
              icon="el-icon-link"
              >Whatsapp
            </el-link>
          </div>
          <!-- <div class="r-template-1-dialog-share-content-hint">
            {{ $t('general.shareHint') }}
          </div> -->

          <div class="r-template-1-dialog-share-content-links">
            {{ $t('general.shareCopyUrl') }}
          </div>
          <div class="r-template-1-dialog-share-content-input">
            <el-input
              :placeholder="$t('general.shareShowUrl')"
              v-model="link.url"
              :readonly="true"
            >
              <el-button
                slot="append"
                :icon="link.copied ? 'el-icon-check' : 'el-icon-document-copy'"
                @click="handleCopyUrl"
              ></el-button>
            </el-input>
          </div>
          <div class="r-template-1-dialog-navigation"></div>
          <div class="ra-dialog-navigation-shadow" />
        </div>
      </el-dialog>

      <!-- Dialog Menues -->
      <el-dialog
        v-if="menues.selected"
        :visible.sync="menues.dialog.visible"
        :append-to-body="true"
        @closed="handleCloseMenuesDialog"
        :key="`dialog-menu-${language}-${menues.selected.rmm_id}`"
        class="r-template-1-dialog r-template-1-dialog-menues"
        :class="{ 'r-template-1-dialog-menues-below': item.dialog.visible }"
      >
        <div class="r-template-1-dialog-menues-header">
          <div class="r-template-1-dialog-menues-header-bkg">
            <div
              class="r-template-1-dialog-menues-header-title"
              :key="`dialog-menues-title-${language}-${menues.selected.rmm_id}`"
            >
              {{ getText(menues.selected.rmm_name_lh).toUpperCase() }}
            </div>
            <div
              v-if="!menues.dialog.promo"
              class="r-template-1-dialog-menues-header-link"
              @click="showDialogShareLink('m', menues.selected.rmm_id)"
            >
              <i class="el-icon-share" />
            </div>

            <div
              class="r-template-1-dialog-menues-header-close"
              @click="menues.dialog.visible = false"
            >
              <i class="el-icon-close" />
            </div>
          </div>
        </div>

        <div class="r-template-1-dialog-menues-content">
          <div class="r-template-1-dialog-menues-content-image">
            <img
              v-if="menues.selected.rmm_image"
              :src="getImageURI(menues.selected.rmm_image, 'hd')"
            />
          </div>
          <div
            class="r-template-1-dialog-menues-content-timeframe"
            v-if="menues.selected.rmm_from || menues.selected.rmm_to"
          >
            <div
              class="r-template-1-dialog-menues-content-timeframe-group"
              v-if="menues.selected.rmm_from"
            >
              <span
                v-if="menues.selected.rmm_from"
                class="r-template-1-dialog-menues-content-timeframe-title"
                >{{ $t('general.from') }}</span
              >
              <span
                class="r-template-1-dialog-menues-content-timeframe-value"
                >{{ toLocal(menues.selected.rmm_from) }}</span
              >
            </div>
            <div v-if="menues.selected.rmm_from && menues.rmm_to">
              <div class="r-template-1-dialog-menues-content-timeframe-group">
                <span><i class="el-icon-right" /></span>
              </div>
            </div>
            <div v-if="menues.selected.rmm_to">
              <div class="r-template-1-dialog-menues-content-timeframe-group">
                <span
                  v-if="menues.selected.rmm_to"
                  class="r-template-1-dialog-menues-content-timeframe-title"
                  >{{ $t('general.until') }}</span
                >
                <span
                  class="r-template-1-dialog-menues-content-timeframe-value"
                  >{{ toLocal(menues.selected.rmm_to) }}</span
                >
              </div>
            </div>
          </div>
          <div
            v-if="menues.selected.rmm_from || menues.selected.rmm_to"
            class="r-template-1-dialog-menues-content-weekdays"
          >
            <div
              v-if="
                menues.selected.rmm_weekdays > 0 &&
                menues.selected.rmm_weekdays < 127
              "
              class="r-template-1-dialog-menues-content-weekdays"
            >
              <template v-for="weekday in weekdays">
                <span
                  v-if="menues.selected.rmm_weekdays & weekday.value"
                  :key="`menues-weekday-${weekday.value}-${menues.selected.rmm_id}`"
                  >{{ weekday.label }}</span
                >
              </template>
            </div>
          </div>
          <div
            class="r-template-1-dialog-menues-content-available"
            v-if="!getSelectedMenuAvailable"
          >
            {{ $t('restaurant.notAvailable') }}
          </div>
          <div
            class="r-template-1-dialog-menues-content-desc"
            v-if="menues.selected.rmm_text_fb.length"
            v-html="getText(menues.selected.rmm_text_lh)"
            :key="`dialog-menues-text-${language}-${menues.selected.rmm_id}`"
          ></div>
          <div
            v-if="getSelectedMenuCategories.length"
            class="r-template-1-dialog-menues-content-cats"
          >
            <div
              v-for="rmcu in getSelectedMenuCategories"
              class="r-template-1-dialog-menues-content-cat"
              :key="`menues-${rmcu.rmcu_rmm}-${language}-${rmcu.rmcu_id}`"
            >
              <h3 class="r-template-1-dialog-menues-content-cat-title">
                {{ getText(rmcu.rmcu_name_lh) }}
              </h3>
              <div class="r-template-1-dialog-menues-content-cat-items">
                <div
                  v-for="rmi in getMenuesIncludedItems(rmcu.rmcu_id)"
                  class="
                    r-template-1-dialog-menues-content-cat-item
                    r-template-1-segment-menu-cat-item
                  "
                  :key="`menues-${rmcu.rmcu_rmm}-${language}-${rmcu.rmcu_id}-${rmi.rmi_id}`"
                  @click="showDialogInfoItem(rmi)"
                >
                  <div class="r-template-1-segment-menu-cat-item-image">
                    <img
                      v-if="rmi.rmi_image"
                      :src="getImageURI(rmi.rmi_image, 'xs')"
                      loading="lazy"
                    />
                    <div v-else class="r-template-1-img-fallback" />
                  </div>
                  <div
                    class="r-template-1-segment-menu-cat-item-title"
                    :key="`item-title-${language}-${rmi.rmi_id}`"
                  >
                    {{ getText(rmi.rmi_name_lh) }}
                  </div>
                  <div class="r-template-1-segment-menu-cat-item-price">
                    <i class="el-icon-success" />
                  </div>
                </div>
                <div
                  v-if="getMenuesSelectItems(rmcu.rmcu_id).length"
                  class="r-template-1-dialog-menues-content-cat-item-group"
                >
                  <div
                    class="
                      r-template-1-dialog-menues-content-cat-item-group-hint
                    "
                  >
                    {{ $t('general.choose') }}
                  </div>
                  <div
                    v-for="rmi in getMenuesSelectItems(rmcu.rmcu_id)"
                    class="
                      r-template-1-dialog-menues-content-cat-item
                      r-template-1-segment-menu-cat-item
                    "
                    :key="`menues-${rmcu.rmcu_rmm}-${language}-${rmcu.rmcu_id}-${rmi.rmi_id}`"
                    @click="showDialogInfoItem(rmi)"
                  >
                    <div class="r-template-1-segment-menu-cat-item-image">
                      <img
                        v-if="rmi.rmi_image"
                        :src="getImageURI(rmi.rmi_image, 'xs')"
                        loading="lazy"
                      />
                      <div v-else class="r-template-1-img-fallback" />
                    </div>
                    <div
                      class="r-template-1-segment-menu-cat-item-title"
                      :key="`item-title-${language}-${rmi.rmi_id}`"
                    >
                      {{ getText(rmi.rmi_name_lh) }}
                    </div>
                    <div class="r-template-1-segment-menu-cat-item-price">
                      <template
                        v-if="
                          menues.itemsSelected.find((el) => el === rmi.rmi_id)
                        "
                      >
                        <div
                          class="
                            r-template-1-dialog-menues-content-cat-item-group-selected
                          "
                        >
                          <template v-if="rmi.rmi_price > 0">
                            <span
                              class="
                                r-template-1-dialog-menues-content-cat-item-group-selected-price
                              "
                            >
                              <span class="r-template-1-price-plus">
                                <i class="el-icon-plus" />
                              </span>
                              <span class="r-template-1-price">{{
                                formatPrice(rmi.rmi_price)
                              }}</span>
                              <span class="r-template-1-price-currency">{{
                                getRestaurant.r_currency_sign
                              }}</span>
                            </span>
                          </template>
                          <i class="el-icon-success" />
                        </div>
                      </template>
                      <template v-else>
                        <el-button
                          type="primary"
                          size="small"
                          :plain="true"
                          @click.stop="
                            selectFromMenues(rmi.rmi_rmcu, rmi.rmi_id)
                          "
                        >
                          <template v-if="rmi.rmi_price > 0">
                            <span class="r-template-1-price-plus">
                              <i class="el-icon-plus" />
                            </span>
                            <span class="r-template-1-price">{{
                              formatPrice(rmi.rmi_price)
                            }}</span>
                            <span class="r-template-1-price-currency">{{
                              getRestaurant.r_currency_sign
                            }}</span>
                          </template>
                          <template v-else>
                            <span class="r-template-1-price">{{
                              $t('general.choose')
                            }}</span>
                          </template>
                        </el-button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ra-dialog-navigation">
          <div class="ra-dialog-navigation-item-left">
            <el-button
              type="text"
              size="medium"
              icon="el-icon-close"
              @click="menues.dialog.visible = false"
              >{{ $t('general.close') }}</el-button
            >
          </div>
          <div v-if="!(menues.dialog.promo && menues.selected.rmm_price === 0)" class="ra-dialog-navigation-item">
            <el-tooltip
              placement="top"
              :disabled="getSelectedMenuBuyable"
              :content="
                getSelectedMenuAvailable
                  ? $t('restaurant.notAvailHintItems')
                  : $t('restaurant.notAvailHintTimeframe')
              "
            >
              <span>
                <el-button
                  type="primary"
                  size="medium"
                  @click="cartAddItem('rmm', menues.selected.rmm_id)"
                  :disabled="!getSelectedMenuBuyable"
                >
                  <i
                    class="r-template-1-dialog-button-price-icon"
                    :class="
                      !isTakeaway || menues.selected.rmm_takeaway === 0
                        ? 'el-icon-s-claim'
                        : 'el-icon-sell'
                    "
                  />
                  <span class="r-template-1-dialog-button-price">
                    {{ formatPrice(getSelectedMenuPrice) }}
                  </span>
                  <span class="r-template-1-dialog-button-price-currency">
                    {{ getRestaurant.r_currency_sign }}
                  </span>
                </el-button>
              </span>
            </el-tooltip>
          </div>
        </div>
        <div class="ra-dialog-navigation-shadow" />
      </el-dialog>

      <!-- Dialog Item -->
      <el-dialog
        v-if="item.selected"
        :visible.sync="item.dialog.visible"
        :append-to-body="true"
        :key="`dialog-item-${language}-${item.selected.rmi_id}`"
        @closed="handleCloseItemDialog()"
        class="r-template-1-dialog r-template-1-dialog-item"
        :class="{ 'r-template-1-dialog-item-over': menues.dialog.visible }"
      >
        <div class="r-template-1-dialog-item-header">
          <div class="r-template-1-dialog-item-header-bkg">
            <div
              class="r-template-1-dialog-item-header-title"
              :key="`dialog-item-title-${language}-${item.selected.rmi_id}`"
            >
              {{ getText(item.selected.rmi_name_lh).toUpperCase() }}
            </div>
            <div
              v-if="item.selected.rmi_rmm === 0 && !item.dialog.promo"
              class="r-template-1-dialog-item-header-link"
              @click="showDialogShareLink('i', item.selected.rmi_id)"
            >
              <i class="el-icon-share" />
            </div>
            <div
              class="r-template-1-dialog-item-header-close"
              @click="item.dialog.visible = false"
            >
              <i class="el-icon-close" />
            </div>
          </div>
        </div>
        <div class="r-template-1-dialog-item-content">
          <div class="r-template-1-dialog-item-content-image">
            <img
              v-if="item.selected.rmi_image"
              :src="getImageURI(item.selected.rmi_image, 'hd')"
            />
          </div>
          <div
            v-if="item.selected.allergens.length > 0"
            class="r-template-1-dialog-item-content-allergens"
          >
            <ul class="eicon-list">
              <li
                v-for="(element, index) in item.selected.allergens"
                :key="`dialog-item-tooltip-${language}-${item.selected.rmi_id}-${index}`"
                class="eicon-menu"
                :class="
                  getAllergensRestaurant.find(
                    (item) => item.ratr_id === element
                  ).rat_group === 0
                    ? 'suitable-level-yes'
                    : 'allergen-level-yes'
                "
              >
                <el-tooltip
                  class="item"
                  :content="
                    getText(
                      getAllergensRestaurant.find(
                        (item) => item.ratr_id === element
                      ).ratr_name_lh
                    )
                  "
                  placement="top-start"
                >
                  <span
                    :class="
                      'eicon eicon-circle-' +
                      getAllergensRestaurant.find(
                        (item) => item.ratr_id === element
                      ).rat_class
                    "
                  ></span>
                </el-tooltip>
              </li>
            </ul>
          </div>
          <div
            class="r-template-1-dialog-item-content-desc"
            v-if="item.selected.rmi_text_fb.length"
            v-html="getText(item.selected.rmi_text_lh)"
            :key="`dialog-item-text-${language}-${item.selected.rmi_id}`"
          ></div>
        </div>

        <div class="ra-dialog-navigation">
          <div class="ra-dialog-navigation-item-left">
            <el-button
              type="text"
              size="medium"
              icon="el-icon-close"
              @click="item.dialog.visible = false"
              >{{ $t('general.close') }}</el-button
            >
          </div>
          <div
            v-if="item.selected.rmi_rmm === 0 && !(item.dialog.promo && item.selected.rmi_price === 0)"
            class="ra-dialog-navigation-item"
          >
            <el-button
              type="primary"
              size="medium"
              @click="cartAddItem('rmi', item.selected.rmi_id)"
            >
              <i
                class="r-template-1-dialog-button-price-icon"
                :class="
                  !isTakeaway || item.selected.rmi_takeaway === 0
                    ? 'el-icon-s-claim'
                    : 'el-icon-sell'
                "
              />
              <span class="r-template-1-dialog-button-price">
                {{ formatPrice(item.selected.rmi_price) }}
              </span>
              <span class="r-template-1-dialog-button-price-currency">
                {{ getRestaurant.r_currency_sign }}
              </span>
            </el-button>
          </div>
        </div>
        <div class="ra-dialog-navigation-shadow" />
      </el-dialog>

      <!-- Dialog Order -->
      <el-dialog
        title="Orden"
        :visible.sync="order.dialog.visible"
        :append-to-body="true"
        class="r-template-1-dialog r-template-1-dialog-order"
        :key="`dialog-order-${language}`"
      >
        <div class="r-template-1-dialog-order-header">
          <div class="r-template-1-dialog-order-header-bkg">
            <div class="r-template-1-dialog-order-header-title">
              {{ $t('restaurant.myOrder') }}
            </div>
            <div
              v-if="order.cart.items.length"
              class="r-template-1-dialog-order-header-clear"
              @click="cartClear"
            >
              <i class="el-icon-delete" />
            </div>
            <div
              class="r-template-1-dialog-order-header-close"
              @click="order.dialog.visible = false"
            >
              <i class="el-icon-close" />
            </div>
          </div>
        </div>
        <div class="r-template-1-dialog-order-content">
          <div class="r-template-1-dialog-order-content-header-bkg"></div>
          <div
            v-if="order.cart.items.length === 0"
            class="r-template-1-dialog-order-content-empty"
          >
            {{ $t('restaurant.empty') }}
          </div>
          <template v-else>
            <div
              v-if="getCartMenuesOrdered.length"
              class="r-template-1-dialog-order-content-menues"
            >
              <h3>{{ $t('general.menus') }}</h3>
              <template v-for="rmm in getCartMenuesOrdered">
                <template v-for="(cItem, cIdx) of order.cart.items">
                  <div
                    v-if="cItem.type === 'rmm' && cItem.ref === rmm.rmm_id"
                    class="r-template-1-dialog-order-item"
                    :key="`order-rmm-${rmm.rmm_id}-${cIdx}`"
                  >
                    <div class="r-template-1-dialog-order-item-title">
                      <div class="r-template-1-dialog-order-item-title-amount">
                        <span
                          v-if="cItem.amount < 2"
                          class="
                            r-template-1-dialog-order-item-title-amount-button
                          "
                          @click="cartItemRemove(cIdx)"
                        >
                          <i class="el-icon-delete" />
                        </span>
                        <span
                          v-else
                          class="
                            r-template-1-dialog-order-item-title-amount-button
                          "
                          @click="cartItemAmountMinus(cIdx)"
                        >
                          <i class="el-icon-minus" />
                        </span>
                        <span
                          class="
                            r-template-1-dialog-order-item-title-amount-text
                          "
                          >{{ cItem.amount }}</span
                        >
                        <span
                          class="
                            r-template-1-dialog-order-item-title-amount-button
                          "
                          @click="cartItemAmountPlus(cIdx)"
                        >
                          <i class="el-icon-plus" />
                        </span>
                      </div>
                      <div class="r-template-1-dialog-order-item-title-text">
                        {{ getText(rmm.rmm_name_lh) }}
                      </div>
                      <div class="r-template-1-dialog-order-item-title-price">
                        <i
                          v-if="order.dialog.takeaway && rmm.rmm_takeaway === 1"
                          class="el-icon-sell r-template-1-price-takeaway"
                        />
                        <span class="r-template-1-price">{{
                          formatPrice(rmm.rmm_price)
                        }}</span>
                        <span class="r-template-1-price-currency">{{
                          getRestaurant.r_currency_sign
                        }}</span>
                      </div>
                    </div>
                    <template v-if="cItem.items.length">
                      <div class="r-template-1-dialog-order-item-options">
                        <div
                          v-for="cSubItem in cItem.items"
                          class="r-template-1-dialog-order-item-option"
                          :key="`order-rmm-${rmm.rmm_id}-${cIdx}-${cSubItem}`"
                        >
                          <div
                            class="r-template-1-dialog-order-item-option-title"
                          >
                            <span
                              class="
                                r-template-1-dialog-order-item-option-title-selected
                              "
                            >
                              <i class="el-icon-check" />
                            </span>
                            {{
                              getText(
                                getMenuItems.find(
                                  (rmi) => rmi.rmi_id === cSubItem
                                ).rmi_name_lh
                              )
                            }}
                          </div>
                          <div
                            class="r-template-1-dialog-order-item-option-price"
                            v-if="
                              getMenuItems.find(
                                (rmi) => rmi.rmi_id === cSubItem
                              ).rmi_price > 0
                            "
                          >
                            <span class="r-template-1-price-plus">
                              <i class="el-icon-plus" />
                            </span>
                            <span class="r-template-1-price">{{
                              formatPrice(
                                getMenuItems.find(
                                  (rmi) => rmi.rmi_id === cSubItem
                                ).rmi_price
                              )
                            }}</span>
                            <span class="r-template-1-price-currency">{{
                              getRestaurant.r_currency_sign
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </template>
            </div>
            <div
              v-for="rmcu in getCartMenuCategoriesOrdered"
              class="r-template-1-dialog-order-content-category"
              :key="`order-rmi-${rmcu.rmcu_id}`"
            >
              <h3>{{ getText(rmcu.rmcu_name_lh) }}</h3>
              <template v-for="(cItem, cIdx) of order.cart.items">
                <div
                  v-if="
                    cItem.type === 'rmi' &&
                    getMenuItems.find(
                      (rmi) =>
                        rmi.rmi_id === cItem.ref &&
                        rmi.rmi_rmcu === rmcu.rmcu_id
                    )
                  "
                  class="r-template-1-dialog-order-item"
                  :key="`order-rmi-${rmcu.rmcu_id}-${cIdx}`"
                >
                  <div class="r-template-1-dialog-order-item-title">
                    <div class="r-template-1-dialog-order-item-title-amount">
                      <span
                        v-if="cItem.amount < 2"
                        class="
                          r-template-1-dialog-order-item-title-amount-button
                        "
                        @click="cartItemRemove(cIdx)"
                      >
                        <i class="el-icon-delete" />
                      </span>
                      <span
                        v-else
                        class="
                          r-template-1-dialog-order-item-title-amount-button
                        "
                        @click="cartItemAmountMinus(cIdx)"
                      >
                        <i class="el-icon-minus" />
                      </span>
                      <span
                        class="r-template-1-dialog-order-item-title-amount-text"
                        >{{ cItem.amount }}</span
                      >
                      <span
                        class="
                          r-template-1-dialog-order-item-title-amount-button
                        "
                        @click="cartItemAmountPlus(cIdx)"
                      >
                        <i class="el-icon-plus" />
                      </span>
                    </div>
                    <div class="r-template-1-dialog-order-item-title-text">
                      {{
                        getText(
                          getMenuItems.find((rmi) => rmi.rmi_id === cItem.ref)
                            .rmi_name_lh
                        )
                      }}
                    </div>
                    <div class="r-template-1-dialog-order-item-title-price">
                      <i
                        v-if="
                          order.dialog.takeaway &&
                          getMenuItems.find((rmi) => rmi.rmi_id === cItem.ref)
                            .rmi_takeaway === 1
                        "
                        class="el-icon-sell r-template-1-price-takeaway"
                      />
                      <span class="r-template-1-price">{{
                        formatPrice(cItem.price)
                      }}</span>
                      <span class="r-template-1-price-currency">{{
                        getRestaurant.r_currency_sign
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="r-template-1-dialog-order-content-total">
              <span class="r-template-1-price">{{
                formatPrice(getCartPrice)
              }}</span>
              <span class="r-template-1-price-currency">{{
                getRestaurant.r_currency_sign
              }}</span>
            </div>
            <div
              v-if="order.dialog.takeaway"
              class="r-template-1-dialog-order-content-comment"
            >
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                :placeholder="$t('restaurant.notes')"
                v-model="order.cart.comment"
              >
              </el-input>
              <p style="margin-top: 10px;">
                ⚠️ {{ $t('restaurant.takeAwayHint') }}
              </p>
            </div>

            <div v-if="isTakeaway" class="ra-dialog-navigation">
              <div class="ra-dialog-navigation-item-left">
                <el-switch
                  v-model="order.dialog.takeaway"
                  active-color="tomato"
                  :active-text="$t('general.takeAway')"
                >
                </el-switch>
              </div>
              <div
                v-if="order.dialog.takeaway"
                class="ra-dialog-navigation-item"
              >
                <el-button
                  type="primary"
                  size="medium"
                  :loading="order.dialog.loading"
                  :disabled="!isCartTakeaway"
                  @click="makeOrderToTakeAway"
                >
                  <template v-if="!checkUser">
                    {{ $t('restaurant.needToRegister') }}
                  </template>
                  <template v-else>
                    {{ $t('restaurant.orderPlace') }}
                  </template>
                </el-button>
              </div>
            </div>
            <div class="ra-dialog-navigation-shadow" />
          </template>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PERM_ADMIN } from '../constants'
import ContactMap from '../components/ContactMap.vue'
export default {
  components: { ContactMap },
  props: {
    language: { type: String, default: 'en' }
  },
  data () {
    return {
      loading: false,
      premiumPreview: false,
      about: {
        full: false,
        collapsable: false,
        recalc: true
      },
      location: null,
      id: 0,
      item: {
        dialog: {
          visible: false,
          promo: false
        },
        selected: null
      },
      link: {
        url: '',
        copied: false,
        dialog: {
          visible: false
        },
        selected: null
      },
      menues: {
        dialog: {
          visible: false,
          promo: false
        },
        selected: null,
        itemsSelected: []
      },
      order: {
        cart: {
          items: [],
          comment: ''
        },
        dialog: {
          visible: false,
          takeaway: false,
          loading: false
        }
      },
      promo: {
        id: null,
        type: null
      }
    }
  },
  created () {
    this.loading = true
    this.id = parseInt(this.$route.params.id)
    if (this.id !== 0) {
      this.sendNewVisit('restaurants', this.id)
      this.loadRestaurant()
    } else {
      this.loading = false
    }
  },
  updated () {
    this.$nextTick(() => {
      this.handleResizeAbout()
    })
  },
  watch: {
    language () {
      this.about.recalc = true
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getOrder',
      'getOrderLastIdItem',
      'getMediaURI',
      'getRestaurant',
      'getCuisineUsages',
      'getMenuCategoryUsages',
      'getAllergensRestaurant',
      'getMenues',
      'getMenuItems',
      'getImages',
      'getGalleryImages',
      'getLanguages',
      'getTimeFrames',
      'isPremium',
      'canPreview',
      'showPremium'
    ]),
    PERM_ADMIN () {
      return PERM_ADMIN
    },
    checkUser () {
      if (this.getUser.id > 0) return true
      else return false
    },
    weekdays () {
      return [
        {
          label: this.$t('locale.weekdayMonday'),
          short: this.$t('locale.weekdayMondayShort'),
          value: 1
        },
        {
          label: this.$t('locale.weekdayTuesday'),
          short: this.$t('locale.weekdayTuesdayShort'),
          value: 2
        },
        {
          label: this.$t('locale.weekdayWednesday'),
          short: this.$t('locale.weekdayWednesdayShort'),
          value: 4
        },
        {
          label: this.$t('locale.weekdayThursday'),
          short: this.$t('locale.weekdayThursdayShort'),
          value: 8
        },
        {
          label: this.$t('locale.weekdayFriday'),
          short: this.$t('locale.weekdayFridayShort'),
          value: 16
        },
        {
          label: this.$t('locale.weekdaySaturday'),
          short: this.$t('locale.weekdaySaturdayShort'),
          value: 32
        },
        {
          label: this.$t('locale.weekdaySunday'),
          short: this.$t('locale.weekdaySundayShort'),
          value: 64
        }
      ]
    },
    getLogoStyles () {
      if (this.getRestaurant.r_setting_logo_bkg_type === 1) {
        return 'filter: drop-shadow(0px 0px 2px black);'
      } else if (
        this.getRestaurant.r_setting_logo_bkg_type === 2 &&
        this.getRestaurant.r_setting_logo_bkg_color !== ''
      ) {
        return `background-color: ${this.getRestaurant.r_setting_logo_bkg_color};`
      }
      return ''
    },
    getActiveCategories () {
      if (this.getRestaurant.r_setting_menu_enabled === 0) {
        return []
      }
      return this.getMenuCategoryUsages.filter(
        (item) =>
          item.rmcu_rmm === 0 &&
          !!this.getMenuItems.find(
            (mi) =>
              mi.rmi_rmm === 0 &&
              mi.rmi_visible === 1 &&
              mi.rmi_rmcu === item.rmcu_id
          )
      )
    },
    getActiveMenues () {
      if (this.getRestaurant.r_setting_menues_enabled === 0) {
        return []
      }
      return this.getMenues.filter((item) => this.checkMenuStatus(item))
    },
    getSelectedMenuCategories () {
      if (this.menues.selected && this.menues.selected.rmm_id > 0) {
        return this.getMenuCategoryUsages.filter(
          (item) =>
            item.rmcu_rmm === this.menues.selected.rmm_id &&
            !!this.getMenuItems.find(
              (rmi) => rmi.rmi_rmcu === item.rmcu_id && rmi.rmi_visible === 1
            )
        )
      }
      return []
    },
    getSelectedMenuAvailable () {
      if (
        this.menues.selected &&
        this.menues.selected.rmm_id > 0 &&
        this.menues.selected.rmm_weekdays & this.todayWeekdayValue
      ) {
        const fromDate = this.menues.selected.rmm_from
          ? this.toTimestamp(this.menues.selected.rmm_from)
          : null
        const toDate = this.menues.selected.rmm_to
          ? this.toTimestamp(this.menues.selected.rmm_to)
          : null
        const now = Math.floor(Date.now() / 1000)
        return this.checkValidDate(fromDate, toDate, now)
      }
      return false
    },
    getSelectedMenuBuyable () {
      if (this.getSelectedMenuAvailable) {
        for (const rmcu of this.getSelectedMenuCategories) {
          const rmcuRmis = this.getMenuesSelectItems(rmcu.rmcu_id)
          if (
            rmcuRmis.length > 0 &&
            !rmcuRmis.find((rmi) =>
              this.menues.itemsSelected.find((item) => item === rmi.rmi_id)
            )
          ) {
            return false
          }
        }
        return true
      }
      return false
    },
    getSelectedMenuPrice () {
      let price = 0.0
      if (this.menues.selected && this.menues.selected.rmm_id > 0) {
        price = this.menues.selected.rmm_price
        for (const item of this.menues.itemsSelected) {
          const rmi = this.getMenuItems.find((el) => el.rmi_id === item)
          if (rmi && rmi.rmi_price > 0) {
            price += rmi.rmi_price
          }
        }
      }
      return price
    },
    getCuisinesOrdered () {
      const cuisines = []
      if (this.getCuisineUsages.find((element) => element.rcu_type === 1)) {
        cuisines.push(
          this.getCuisineUsages.find((element) => element.rcu_type === 1)
        )
      }
      if (this.getCuisineUsages.find((element) => element.rcu_type === 2)) {
        cuisines.push(
          this.getCuisineUsages.find((element) => element.rcu_type === 2)
        )
      }
      this.getCuisineUsages
        .filter((element) => element.rcu_type === 0)
        .forEach((cuisine) => {
          cuisines.push(cuisine)
        })
      return cuisines
    },
    getRegularHours () {
      const tfs = []
      for (let i = 0; i < this.getTimeFrames.length; i++) {
        if (this.getTimeFrames[i].rft_origin === 0) {
          const tf = this.deepCopy(this.getTimeFrames[i])
          tf.rft_from = this.extractTimeFromDateTime(
            this.getTimeFrames[i].rft_from
          )
          tf.rft_to = this.extractTimeFromDateTime(this.getTimeFrames[i].rft_to)
          tfs.push(tf)
        }
      }
      return tfs
    },
    getClosedDates () {
      const tfs = []
      const now = Math.floor(Date.now() / 1000)
      for (const tf of this.getTimeFrames.filter(
        (item) => item.rft_origin === 1
      )) {
        const fromDate = tf.rft_from ? this.toTimestamp(tf.rft_from) : null
        const toDate = tf.rft_to ? this.toTimestamp(tf.rft_to) : null
        if (this.checkValidDate(fromDate, toDate, now, true)) {
          tfs.push({
            from: this.toLocal(tf.rft_from),
            to: this.toLocal(tf.rft_to)
          })
        }
      }
      return tfs
    },
    getCartMenuesOrdered () {
      return this.getActiveMenues.filter((rmm) =>
        this.order.cart.items.find(
          (cartItem) => cartItem.type === 'rmm' && cartItem.ref === rmm.rmm_id
        )
      )
    },
    getCartMenuCategoriesOrdered () {
      return this.getActiveCategories.filter((rmcu) =>
        this.getMenuItems.find(
          (rmi) =>
            this.order.cart.items.find(
              (cartItem) =>
                cartItem.type === 'rmi' && cartItem.ref === rmi.rmi_id
            ) && rmi.rmi_rmcu === rmcu.rmcu_id
        )
      )
    },
    getCartPrice () {
      let total = 0.0
      if (this.order.cart.items.length > 0) {
        for (const item of this.order.cart.items) {
          total += item.amount * item.price
        }
      }
      return total
    },
    isCartTakeaway () {
      if (this.order.cart.items.length > 0) {
        for (const item of this.order.cart.items) {
          if (!item.takeaway) {
            return false
          }
        }
      }
      return true
    },
    isTakeaway () {
      return this.getRestaurant.r_takeaway === 1 && this.showPremium
    },
    todayWeekdayValue () {
      let day = new Date().getDay()
      // we start with 0 for monday, not sunday
      if (day === 0) {
        day = 7
      }
      day--
      return Math.pow(2, day)
    }
  },
  methods: {
    handleResizeAbout () {
      this.$nextTick(() => {
        if (this.$refs.about && typeof this.$refs.about !== 'undefined') {
          if (this.$refs.about.clientHeight <= 200) {
            this.about.collapsable = false
            this.about.full = true
          } else {
            this.about.collapsable = true
            if (this.about.recalc) {
              this.about.full = false
            }
          }
          this.about.recalc = false
        }
      })
    },
    extractTimeFromDateTime (dateTime) {
      return dateTime.substr(11, 5)
    },
    getText (handle) {
      if (handle && handle.fb) {
        if (this.showPremium) {
          if (
            handle.values &&
            handle.values[this.language] &&
            handle.values[this.language].trim() !== ''
          ) {
            return handle.values[this.language].trim()
          } else if (handle.fb !== '') {
            return handle.fb
          }
        } else {
          if (handle.fb !== '') {
            return handle.fb
          } else if (
            handle.values &&
            handle.values[this.language] &&
            handle.values[this.language].trim() !== ''
          ) {
            return handle.values[this.language].trim()
          }
        }
      }
      return ''
    },
    formatPrice (price) {
      let res = `${price}`
      if (res !== '0' && res.includes('.')) {
        res = parseFloat(price).toFixed(2).replace('.', ',')
      }
      return res
    },
    sendNewVisit (statType = 'restaurants', statData = false) {
      this.$http
        .post('stats/new_visit', {
          type: statType,
          id_restaurant: this.id,
          data: statData || this.id,
          device: this.$device.machine.type
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error.body ? error.body : 'restaurant stat error')
          }
        )
    },
    showSharedModal () {
      if (this.$route.params.itemType && this.$route.params.itemID) {
        const itemID = parseInt(this.$route.params.itemID)
        if (this.$route.params.itemType === 'm') {
          this.showDialogMenues(
            this.getMenues.find((item) => item.rmm_id === itemID)
          )
        } else if (this.$route.params.itemType === 'i') {
          this.showDialogInfoItem(
            this.getMenuItems.find((item) => item.rmi_id === itemID)
          )
        }
      } else {
        const promoMenu = this.getMenues.find((item) => item.rmm_promo === 1)
        if (promoMenu) {
          setTimeout(() => { this.showDialogMenues(promoMenu, true) }, 2000)
        } else {
          const promoItem = this.getMenuItems.find((item) => item.rmi_promo === 1)
          if (promoItem) {
            setTimeout(() => { this.showDialogInfoItem(promoItem, true) }, 2000)
          }
        }
      }
    },
    loadRestaurant () {
      this.$store.commit('clearRestaurant')
      this.$http
        .post('restaurant/item/', { id: this.id })
        .then(
          (response) => {
            if (!response.body.error) {
              if (response.body.restaurants.r_id) {
                this.$store.commit('setRestaurant', response.body)
                this.$emit('menuoptionsset', {
                  menues: !!this.getActiveMenues.length,
                  menu: !!this.getActiveCategories.length,
                  sections: (!!this.getActiveCategories.length) && this.getRestaurant.r_setting_menu_sections_show === 1,
                  categories: this.getActiveCategories
                })
                if (this.getRestaurant.r_location) {
                  const parts = this.getRestaurant.r_location.split(',')
                  if (parts.length === 2) {
                    this.location = {
                      lat: parseFloat(parts[1]),
                      lng: parseFloat(parts[0])
                    }
                  }
                }
                this.showSharedModal()
              }
            } else {
              console.log('err', response.body.error)
            }
          },
          (error) => {
            console.log(error.body ? error.body : 'restaurant load error')
          }
        )
        .then(() => {
          this.loading = false
        })
    },
    getImageURI (id, size) {
      const image = this.getImages.find((item) => item.ri_id === id)
      if (image) {
        return `${this.getMediaURI}${size}/${image.ri_file}`
      }
      return ''
    },
    checkMenuStatus (menu) {
      const fromDate = menu.rmm_from && this.showPremium ? this.toTimestamp(menu.rmm_from) : null
      const toDate = menu.rmm_to && this.showPremium ? this.toTimestamp(menu.rmm_to) : null
      const fromVisibleDate = menu.rmm_visible_from && this.showPremium
        ? this.toTimestamp(menu.rmm_visible_from)
        : null
      const toVisibleDate = menu.rmm_visible_to && this.showPremium
        ? this.toTimestamp(menu.rmm_visible_to)
        : null

      let status = false

      if (menu.rmm_visible) {
        const now = Math.floor(Date.now() / 1000)

        if (fromVisibleDate || toVisibleDate) {
          if (this.checkValidDate(fromVisibleDate, toVisibleDate, now)) {
            status = this.checkValidDate(fromDate, toDate, now, true)
          }
        } else {
          status = this.checkValidDate(fromDate, toDate, now)
        }
      }

      return status
    },
    toLocal (strDate) {
      return new Date(strDate.replace(' ', 'T')).toLocaleDateString()
    },
    toTimestamp (strDate) {
      const datum = Date.parse(strDate.replace(' ', 'T'))
      return datum / 1000
    },
    checkValidDate (from, to, now, future = false) {
      if (future) {
        if (!to || now <= to) return true
      } else {
        if (!from && !to) return true
        if (!from && to && now <= to) return true
        if (from && !to && now >= from) return true
        if (from && to && now >= from && now <= to) return true
      }
      return false
    },
    getMenuesIncludedItems (catID) {
      return this.getMenuItems.filter(
        (item) =>
          item.rmi_rmcu === catID &&
          item.rmi_visible === 1 &&
          item.rmi_rmm_rmcu_type === 0
      )
    },
    getMenuesSelectItems (catID) {
      return this.getMenuItems.filter(
        (item) =>
          item.rmi_rmcu === catID &&
          item.rmi_visible === 1 &&
          item.rmi_rmm_rmcu_type === 1
      )
    },
    selectFromMenues (rmcuID, rmiID) {
      this.menues.itemsSelected = this.menues.itemsSelected.filter(
        (el) =>
          !this.getMenuItems.find(
            (item) => item.rmi_rmcu === rmcuID && item.rmi_id === el
          )
      )
      this.menues.itemsSelected.push(rmiID)
      this.menues.itemsSelected.sort()
    },
    showDialogMenues (menu, promo = false) {
      if (menu && menu.rmm_id) {
        if (
          !this.menues.selected ||
          this.menues.selected.rmm_id !== menu.rmm_id
        ) {
          this.menues.selected = menu
          this.menues.itemsSelected = []
        }
        this.menues.dialog.promo = promo
        this.menues.dialog.visible = true
        this.sendNewVisit('restaurant_menues', menu.rmm_id)
      }
    },
    handleCloseMenuesDialog () {
      this.menues.dialog.visible = false
    },
    showDialogInfoItem (menuItem, promo = false) {
      if (menuItem && menuItem.rmi_id) {
        this.item.selected = menuItem
        this.item.dialog.promo = promo
        this.item.dialog.visible = true
        this.sendNewVisit('restaurant_items', menuItem.rmi_id)
      }
    },
    handleCloseItemDialog () {
      this.item.dialog.visible = false
    },
    showDialogShareLink (type, id) {
      this.link.url = `${this.shareURL}?l=${this.language}&r=${this.getRestaurant.r_id}`
      if (type && id) {
        this.link.url += `&${type}=${id}`
      }
      this.link.copied = false
      this.link.dialog.visible = true
    },
    handleCloseShareLinkDialog () {
      this.link.dialog.visible = false
      this.link.url = ''
    },
    handleCopyUrl () {
      navigator.clipboard.writeText(`${this.link.url}`).then(
        () => {
          this.link.copied = true
          console.log('copy succeeded')
        },
        () => {
          console.log('copy failed')
        }
      )
    },
    getShareUrl () {
      return encodeURIComponent(this.link.url)
    },
    cartClear () {
      this.order.cart = {
        items: []
      }
    },
    cartAddItem (type, ref) {
      const item = this.order.cart.items.find(
        (item) =>
          item.type === type &&
          item.ref === ref &&
          (type !== 'rmm' ||
            JSON.stringify(item.items) ===
              JSON.stringify(this.menues.itemsSelected))
      )

      if (item) {
        item.amount++
      } else {
        const newItem = {
          type: type,
          ref: ref,
          amount: 1,
          items: [],
          takeaway: false,
          price: 0
        }
        if (type === 'rmm') {
          newItem.items = this.deepCopy(this.menues.itemsSelected)
          newItem.takeaway = !!this.menues.selected.rmm_takeaway
          newItem.price = this.getSelectedMenuPrice
          // newItem.data = this.getMenues.find(rmm => rmm.rmm_id === ref)
        } else if (type === 'rmi') {
          newItem.takeaway = !!this.item.selected.rmi_takeaway
          newItem.price = this.item.selected.rmi_price
          // newItem.data = this.getMenuItems.find(rmi => rmi.rmi_id === ref)
        }
        this.order.cart.items.push(newItem)
      }
      if (type === 'rmm') {
        this.handleCloseMenuesDialog()
      } else {
        this.handleCloseItemDialog()
      }
    },
    cartItemRemove (idx) {
      this.order.cart.items.splice(idx, 1)
    },
    cartItemAmountPlus (idx) {
      this.order.cart.items[idx].amount++
    },
    cartItemAmountMinus (idx) {
      if (this.order.cart.items[idx].amount > 1) {
        this.order.cart.items[idx].amount--
      }
    },
    showDialogOrder () {
      this.order.dialog.visible = true
    },
    makeOrderToTakeAway () {
      if (!this.checkUser) {
        this.$emit('showlogin', true)
        this.$nextTick(() => {
          // TODO: don't like it much ... need a better way for this stuff
          this.$emit('showlogin', false)
        })
      } else {
        this.order.dialog.loading = true
        this.$http
          .post('restaurant/save_new_order/', {
            id_restaurant: this.getRestaurant.r_id,
            lang_restaurant: this.language,
            user: this.getUser,
            cart: this.order.cart
          })
          .then(
            (response) => {
              if (!response.body.error) {
                if (response.body) {
                  this.order.cart = {
                    items: [],
                    comment: ''
                  }
                  this.order.dialog.visible = false
                  this.$notify({
                    title: this.$msg.success,
                    message: `Pedido #${response.body.ro_id} realizado, puede revisarlo en "Mis pedidos"`,
                    type: 'success'
                  })
                }
              } else {
                console.log('err', response.body.error)
              }
            },
            (error) => {
              console.log(error.body ? error.body : 'save order error')
            }
          )
          .then(() => {
            this.order.dialog.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
@import url('../assets/css/icons/allergens/css/style.css');
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&family=Nothing+You+Could+Do&display=swap');
</style>
<style lang="less">
@import url('../assets/css/variables.less');
.r-template {
  position: absolute;
  z-index: 1;
  left: 0;
  top: @header_height;
  right: 0;
  height: e('calc(100vh - @{header_height})');
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}
body .r-template-1,
body .r-template-1-dialog {
  color: #666;
  * {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  ol,
  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  p {
    margin: 0;
    padding: 5px 0;
  }
  h1,
  h2,
  h3 {
    font-family: 'Nothing You Could Do', cursive;
    text-align: center;
    color: tomato;
    font-weight: 600;
  }
  h1 {
    font-size: 180%;
  }

  h2 {
    font-size: 160%;
  }

  h3 {
    font-size: 160%;
  }
  img {
    transition: all 0.2s;
  }
}

.r-template-1 {
  &.lang-ru{
    .r-template-1-segment-menu-cat {
      h2 {
        font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Noto Sans', Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
      }
    }
  }
}

.r-template-1 {
  background: white;
  &-loading {
    text-align: center;
    height: e('calc(100% - @{header_height})');
    overflow: hidden;
    svg {
      width: 100px;
      height: 100%;
      margin: 0;
      padding: 0;
      display: inline-block;
      path {
        fill: tomato;
      }
    }
  }
  &-premium-preview {
    position: fixed;
    opacity: 0.65;
    top: 90px;
    right: 20px;
    // left: 50%;
    // transform: translate(-50%, 0);
    transition: opacity 250ms linear;
    z-index: 999;
    @media (max-width: @break-mobile) {
      top: 80px;
      right: 10px;
    }
    &-on{
      right: 80px;
      @media (max-width: @break-mobile) {
        right: 70px;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  &-img-fallback {
    background-color: darken(linen, 5%);
    background-image: url('../assets/img/restaurania_no_image.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
  }
  &-about {
    max-height: 200px;
    overflow: hidden;
    position: relative;
    &-full {
      max-height: unset;
    }
    &-more {
      position: sticky;
      bottom: 0;
      text-align: center;
      background: white;
      color: tomato;
      cursor: pointer;
      padding: 20px 0 5px 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 1) 30%
      );
      &-text {
        padding-left: 5px;
      }
    }
  }
  &-share {
    position: absolute;
    bottom: 0;
    right: 0;
    &-link {
      cursor: pointer;
      transition: all 0.2s;
      display: inline-block;
      background: fadeout(@color-secondary, 50%);
      color: white;
      padding: 5px 10px;
      &:hover {
        background: fadeout(@color-secondary, 35%);
      }
    }
  }
  &-order {
    position: fixed;
    top: 85px;
    right: 20px;
    z-index: 1;
    i {
      font-size: 24px;
    }
    @media (max-width: @break-mobile) {
      top: 75px;
      right: 10px;
    }
    &-item-count {
      sup {
        margin-top: 2px;
        margin-right: 10px;
      }
      button {
        border: 1px solid white;
      }
    }
    &-list {
      &-item {
        margin: 5px 0;
        &-amount {
          width: 90px;
          margin: 0 10px;
        }
      }
      &-nav {
        margin-top: 20px;
      }
    }
  }
  &-header {
    position: relative;
    line-height: 0;
    &-image {
      width: 100vw;
      height: calc(90vh - @header_height);
      min-height: 50vh;
      background: tomato;
      object-fit: cover;
      @media (max-width: @break-tablet) {
        height: auto;
        max-height: calc(90vh - @header_height);
        min-height: 30vh;
      }
    }
    &-brand {
      position: absolute;
      top: 25px;
      left: 20px;
      max-width: calc(100vw - 100px);
      pointer-events: none;
      @media (max-width: @break-mobile) {
        top: 15px;
        left: 10px;
      }
      &-title {
        letter-spacing: 1px;
        font-size: 150%;
        font-weight: 600;
        color: white;
        line-height: 120%;
        text-shadow: 1px 0 3px @color-secondary, -1px 0 3px @color-secondary,
          0 1px 3px @color-secondary, 0 -1px 3px @color-secondary;
        @media (max-width: @break-mobile) {
          font-size: 130%;
        }
      }
      &-logo {
        width: 25vw;
        @media (max-width: @break-mobile) {
          width: 33vw;
        }
        &-image {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
      & > div + div {
        margin-top: 10px;
      }
    }
  }
  &-segment {
    &-text {
      margin: auto;
      width: 70vw;
      text-align: justify;
      padding: 20px 0 40px 0;
      @media (max-width: @break-tablet) {
        width: 90vw;
      }
    }
    &-menues {
      &-main {
        padding: 20px 0 0 0;
        background: linen;
      }
      &-items {
        display: flex;
        align-items: stretch;
        justify-content: space-around;
        flex-wrap: wrap;
        align-content: stretch;
        padding: 0 20px;
        @media (max-width: @break-tablet) {
          padding: 0 10px;
        }
      }
      &-item {
        box-sizing: border-box;
        padding: 0 20px 40px 20px;
        flex: 1 0 280px;
        max-width: 560px;
        @media (max-width: @break-tablet) {
          padding: 0 10px 20px 10px;
        }
        @media (max-width: @break-mobile) {
          width: 100%;
          max-width: 100%;
        }
        &-content {
          height: 100%;
          background: white;
          overflow: hidden;
          border-radius: @border-radius;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          transition: border 0.2s, color 0.2s;
          border: 1px solid linen;
          cursor: pointer;
          &:hover {
            border-color: tomato;
            color: tomato;
          }
        }
        &-image {
          overflow: hidden;
          width: 100%;
          background: darken(linen, 5%);
          line-height: 0;
          // max-height: 50vh;
          // flex-grow: 1;
          // flex-shrink: 1;
          flex: 0 0 33vh;
          img {
            width: calc(100% + 1px);
            height: 100%;
            object-fit: cover;
          }
        }
        &-title {
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 20px 10px;
        }
        &-timeframe {
          padding: 0 10px 20px 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          &-group {
            display: flex;
            align-items: center;
            color: lighten(@color-font, 25%);
            padding: 0 5px;
            font-size: 80%;
          }
          &-title {
            display: inline-block;
            padding-right: 5px;
          }
          &-value {
            font-weight: bold;
          }
        }
        &-weekdays {
          color: lighten(@color-font, 25%);
          padding: 0 10px 20px 10px;
          font-size: 80%;
          font-weight: bold;
          span {
            &::after {
              content: ', ';
            }
            &:last-of-type {
              &::after {
                content: '';
              }
            }
          }
        }
        &-price {
          display: flex;
          color: tomato;
          padding: 0 10px 20px 10px;
          flex: 1 0 auto;
          align-items: flex-end;
        }
      }
      background: linen;
    }
    &-menu {
      &-main {
        padding: 20px 0 0 0;
      }
      &-cat {
        background: linen;
        padding: 20px 0 40px 0;
        &-image {
          line-height: 0;
          &,
          img {
            width: 100vw;
            height: auto;
            max-height: 50vh;
            object-fit: cover;
          }
        }
        &-items {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: stretch;
          clear: both;
          margin: auto;
        }
        &-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 50%;
          box-sizing: border-box;
          padding: 5px 5vw;
          transition: color 0.2s;
          @media (max-width: @break-tablet) {
            width: 100%;
          }
          @media (max-width: @break-mobile) {
            padding: 5px 10px;
          }
          &:hover {
            color: tomato;
            .r-template-1-segment-menu-cat-item-image {
              border-color: tomato;
            }
          }
          &-image {
            border: 1px solid rgba(255, 99, 71, 0);
            transition: border 0.2s;
            flex: 0 0 71px;
            line-height: 0;
            height: 40px;
            width: 71px;
            border-radius: @border-radius;
            overflow: hidden;
            @media (max-width: @break-mobile) {
              width: 70px;
            }
            img {
              height: calc(100% + 1px); // render bug
              width: calc(100% + 1px); // render bug
              object-fit: cover;
            }
          }
          &-title {
            text-transform: uppercase;
            flex: 1 1 100%;
            box-sizing: border-box;
            padding: 0 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &-price {
            display: flex;
            color: tomato;
          }
        }
      }
    }
    &-footer {
      padding: 20px 0 0 0;
    }
  }
  &-gallery {
    column-count: 3;
    column-gap: 0;
    -moz-column-fill: balance;
    column-fill: balance;
    line-height: 0;
    vertical-align: middle;
    background: @color-secondary;
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
    @media (max-width: @break-tablet) {
      column-count: 2;
    }
    @media (max-width: @break-mobile) {
      column-count: 1;
    }
    &-item {
      display: inline-block;
      padding: 0;
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      img {
        max-width: 100%;
        line-height: 0;
      }
    }
  }
  &-footer {
    &-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 10px 0 0 0;
      & > div {
        flex: 1 1 300px;
        min-width: 300px;
      }
      &-map {
        height: 500px;
      }
      &-restaurant {
        margin: 20px 40px;
      }
      &-contact {
        i {
          margin-right: 5px;
        }
        &-logo {
          max-width: 200px;
          object-fit: contain;
        }
        &-name {
          font-weight: bold;
          font-size: 120%;
          margin: 10px 0;
        }
        &-cuisines {
          margin: 5px 0 15px 0;
          color: lighten(@color-font, 25%);
        }
        &-zip-city {
          margin-bottom: 15px;
        }
        &-infos {
          margin-top: 10px;
          & > div {
            margin-top: 5px;
          }
        }
        &-whatsapp {
          a {
            color: tomato;
          }
        }
      }
      &-hours {
        margin: 20px 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        &-weekday {
          flex: 0 0 120px;
          margin: 0 10px 10px 0;
          &-day {
            font-weight: bold;
          }
          &-times {
            margin: 10px 0 0 0;
            font-size: 80%;
            color: lighten(@color-font, 25%);
          }
          &-time {
            margin: 2px 0;
            &-closed {
              font-style: italic;
            }
          }
        }
        &-closed {
          flex: 0 0 100%;
          margin-top: 20px;
          &-title {
            font-weight: bold;
          }
          &-items {
            margin: 10px 0 0 0;
            font-size: 80%;
            color: lighten(@color-font, 25%);
          }
          &-item {
            margin: 2px 0;
          }
        }
      }
    }
  }
  &-price {
    &-takeaway {
      padding-right: 5px;
    }
    &-plus {
      padding-right: 5px;
    }
    &-takeaway {
      padding-right: 10px;
    }
    &-currency {
      padding-left: 5px;
    }
  }
  .eicon-list {
    margin: 20px 0 0 0;
    li {
      width: 24px;
      height: 24px;
      .eicon {
        font-size: 24px;
      }
    }
  }
}

.r-template-1-dialog {
  .el-dialog {
    width: fit-content;
    &__header {
      display: none;
    }
    &__body {
      padding: 0;
    }
  }
  &-button-price {
    display: inline-block;
    padding-left: 20px;
    &-icon {
      font-size: 18px;
      position: absolute;
      margin-left: -5px;
      margin-top: -3px;
    }
    &-currency {
      display: inline-block;
      padding-left: 5px;
    }
  }
  &-share,
  &-item,
  &-menues,
  &-order {
    transition: transform 0.2s;
    .el-dialog {
      // transition: transform 0.2s;
      width: 95%;
      max-width: 600px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.65);
      &__header {
        display: none !important;
      }
      &__body {
        padding: 0 !important;
      }
    }
    &-over {
      .el-dialog {
        margin-top: 5vh !important;
        width: 90% !important;
        max-width: 560px;
      }
    }
    &-below {
      .el-dialog {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.65);
      }
    }
    &-header {
      position: absolute;
      height: 40px;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 2px 2px 0 0;
      overflow: hidden;
      &-bkg {
        // render-bug
        height: 40px;
        width: calc(100% + 1px); // render-bug
        background: rgba(0, 0, 0, 0.45);
        backdrop-filter: blur(12px);
        display: flex;
        align-items: center;
      }
      &-title {
        flex: 1 2 100%;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        padding: 0 10px;
      }
      &-link {
        flex: 0 0 40px;
        color: @color-primary;
        font-size: 28px;
        text-align: center;
        cursor: pointer;
      }
      &-close {
        flex: 0 0 40px;
        color: @color-primary;
        font-size: 32px;
        text-align: center;
        cursor: pointer;
      }
      &-clear {
        flex: 0 0 40px;
        color: @color-primary;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
      }
    }
    &-content {
      &-image {
        background: @color-secondary;
        min-height: 40px;
        width: 100%;
        height: auto;
        line-height: 0;
        border-radius: 2px 2px 0 0;
        overflow: hidden;
        img {
          padding-top: 38px; // 2px from image for blur-ambient
          object-fit: cover;
          width: calc(100% + 1px); // render-bug
          height: auto;
        }
      }
      &-allergens {
        padding: 20px 20px 0 20px;
        .eicon-list {
          margin: 0;
          padding: 0;
          li {
            width: 24px;
            margin: 0 5px 5px 0;
            span {
              font-size: 24px;
            }
          }
        }
      }
      &-desc {
        padding: 20px;
        text-align: justify;
        word-break: normal;
      }
      &-hint {
        color: grey;
        padding-left: 10px;
      }
      &-links {
        padding: 20px 10px 10px 10px;
      }
      &-buttons {
        padding: 0 10px 10px 10px;
      }
      &-button {
        margin-right: 10px;
        padding: 10px !important;
        background: #fff9eb;
        border: 0.1px solid #f93;
        border-radius: 3px;
      }
      &-input {
        margin: 0 10px 20px 10px;
      }
    }
    .ra-dialog-navigation,
    .ra-dialog-navigation-shadow {
      margin: 0;
    }
  }
  &-menues {
    &-content {
      &-cats {
        .r-template-1-img-fallback {
          background-color: linen;
        }
      }
      &-cat {
        &-items {
          display: flex;
          flex-direction: column;
          padding: 0 10px 0 10px;
          // @media (max-width: @break-mobile) {
          //   padding: 0;
          // }
        }
        &-item {
          width: 100%;
          padding: 5px 10px;
          & + .r-template-1-dialog-menues-content-cat-item-group {
            margin-top: 15px;
          }
          &-group {
            border: 1px solid tomato;
            padding: 5px 0;
            border-radius: @border-radius;
            // @media (max-width: @break-mobile) {
            //   margin: 0 10px;
            // }
            &-hint {
              text-align: center;
              // padding: 5px 10px 5px 0;
              font-style: italic;
              color: tomato;
              // color: lighten(@color-font, 25%);
              padding: 0 5px;
              margin: -17px 0 0 10px;
              background: white;
              position: absolute;
            }
            &-selected {
              color: tomato;
              white-space: nowrap;
              &-price {
                display: inline-block;
                padding-right: 5px;
              }
            }
          }
        }
      }
      &-timeframe {
        padding: 15px 15px 10px 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &-group {
          display: flex;
          align-items: center;
          color: lighten(@color-font, 25%);
          padding: 0 5px;
        }
        &-title {
          display: inline-block;
          padding-right: 5px;
        }
        &-value {
          font-weight: bold;
        }
      }
      &-weekdays {
        text-align: center;
        color: lighten(@color-font, 25%);
        padding: 0 10px;
        font-weight: bold;
        span {
          &::after {
            content: ', ';
          }
          &:last-of-type {
            &::after {
              content: '';
            }
          }
        }
      }
      &-available {
        padding: 10px 20px;
        text-align: center;
        font-weight: bold;
        color: tomato;
      }
    }
  }
  &-share,
  &-order {
    &-content {
      h3 {
        margin: 20px 0 0 0;
      }
      &-header-bkg {
        background: tomato;
        height: 40px;
        border-radius: 2px 2px 0 0;
      }
      &-empty {
        font-style: italic;
        text-align: center;
        padding: 20px 0;
      }
      &-total {
        border-top: 1px dotted tomato;
        color: tomato;
        font-size: 120%;
        text-align: right;
        padding: 10px;
      }
      &-comment {
        padding: 10px;
      }
      // &-links {
      //   padding: 10px;
      //   &-button {
      //     padding: 10px;
      //   }
      // }
    }
    &-item {
      padding: 10px;
      &-title {
        display: flex;
        align-items: stretch;
        align-content: center;
        width: 100%;
        &-amount {
          flex: 0 0 60px;
          white-space: nowrap;
          color: tomato;
          &-button {
            display: inline-block;
            background: linen;
            cursor: pointer;
            text-align: center;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 2px;
            font-size: 11px;
            vertical-align: text-top;
          }
          &-text {
            display: inline-block;
            width: 24px;
            text-align: center;
          }
        }
        &-text {
          flex: 1 1 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 5px;
          box-sizing: border-box;
        }
        &-price {
          white-space: nowrap;
          color: tomato;
        }
      }
      &-option {
        display: flex;
        align-items: stretch;
        align-content: center;
        width: 100%;
        &-title {
          flex: 1 1 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 5px 0 65px;
          box-sizing: border-box;
          &-selected {
            color: tomato;
            margin: 2px 5px 0 0;
            font-size: 11px;
            float: left;
          }
        }
        &-price {
          color: tomato;
          white-space: nowrap;
        }
      }
    }
  }
  &-footer {
    margin-top: 3em;
  }
}
</style>
